import React from 'react'
import Header from './Header'
import Tokenomics from './Tokenomics'
import About from './About'
import Features from './Features'
import Roadmap from './Roadmap'
import Footer from './Footer'
import { useState, useEffect } from 'react'


const Homepage = () => {

    const [tokenomicsHeading, setTokenomicsHeading] = useState('');
    const [tokenomicsPara, setTokenomicsPara] = useState('');
    const [utilizationHeading, setUtilizationHeading] = useState('');
    const [featureHeading, setFeatureHeading] = useState('');
    const [roadmapHeading, setRoadmapHeading] = useState('');
    const [footerPara, setFooterPara] = useState('');
    const [stitchedEmail, setStitchedEmail] = useState('');
    const [copyrightText, setCopyrightText] = useState('');

    const getSettingsData = async () => {

        let response = await fetch("https://stitchedbackend.com/api/stitched/get-stitched-settings", {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        });

        response = await response.json();
        if (response.error) {
            console.warn(response)
        }
        else {
            setTokenomicsHeading(response.settingsData.tokenomics_section_heading);
            setTokenomicsPara(response.settingsData.tokenomics_section_para);
            setUtilizationHeading(response.settingsData.utilization_section_heading);
            setFeatureHeading(response.settingsData.features_section_heading);
            setRoadmapHeading(response.settingsData.roadmap_section_heading);
            setFooterPara(response.settingsData.footer_para);
            setStitchedEmail(response.settingsData.stitched_email);
            setCopyrightText(response.settingsData.copyright_text);
        }

    }

    useEffect(() => {
        getSettingsData();
    }, [])

    return (
        <>
            <Header />
            <Tokenomics tokenomicsHeading={tokenomicsHeading} tokenomicsPara={tokenomicsPara} utilizationHeading={utilizationHeading} />
            <About />
            <Features featuresHeading={featureHeading} />
            <Roadmap roadmapHeading={roadmapHeading} />
            <Footer footerPara={footerPara} stitchedEmail={stitchedEmail} copyrightText={copyrightText} />
        </>
    )
}

export default Homepage