import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import WavyTop from "./WavyTop";
import WavyBottom from "./WavyBottom";
import { useState, useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Features = ({ featuresHeading }) => {

  useEffect(() => {
    AOS.init({ duration: 1000 })
  }, []);

  const [features, setFeatures] = useState([]);

  const getFeatures = async () => {
    let response = await fetch(
      "https://stitchedbackend.com/api/stitched/get-stitched-features",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      }
    );

    response = await response.json();
    if (response.error) {
      console.warn(response);
    } else {
      setFeatures(response.featuresData);

    }
  };

  useEffect(() => {
    getFeatures();
  }, []);


  return (
    <>
      <WavyTop />

      <div
        style={{
          backgroundColor: "#040447",
        }}
        className="features t-white"
        id="features"
      >
        <Container>
          <h2 className="t-white text-center mb-4" data-aos="fade-up">{featuresHeading}</h2>
          <Row>

            {features.map((feature) => (
              <Col md={6} xl={3} className="mt-4 text-center t-white" data-aos="fade-up">
                <img src={`https://stitchedbackend.com/stitched-frontend/features/` + feature['feature_img']} width={80} alt={"Gameplay"} />
                <h3>{feature['feature_heading']}</h3>
                <p>{feature['feature_text']}</p>
              </Col>
            ))}

          </Row>
        </Container>
      </div>
      <WavyBottom />
    </>
  );
};

export default Features;
