import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState, useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Roadmap = ({ roadmapHeading }) => {

  useEffect(() => {
    AOS.init({ duration: 1000 })
  }, []);

  const [roadmapData, setRoadmapData] = useState([]);

  const getRoadmap = async () => {
    let response = await fetch(
      "https://stitchedbackend.com/api/stitched/get-stitched-roadmap",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );

    response = await response.json();
    if (response.error) {
      console.warn(response);
    } else {
      setRoadmapData(response.roadmapData);
    }
  };

  useEffect(() => {
    getRoadmap();
  }, []);

  return (
    <>
      <Container id="roadmap">
        <h2 className="text-center t-white headingText mb-5" data-aos="fade-up">
          {roadmapHeading}
        </h2>
        <Row className="roadmaps-wrapper">
          {roadmapData.map((obj) => (
            <Col md={{ span: 6 }} className="t-white mb-4 roadmap" data-aos="fade-up">
              <div className="d-flex flex-row">
                <div className="roadmap-heading">{obj["heading"]}</div>
                <div>
                  <div className="roadmap-btn">{obj["year"]}</div>
                  <div
                    className="roadmap-text"
                    dangerouslySetInnerHTML={{ __html: obj["text"] }}
                  ></div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default Roadmap;
