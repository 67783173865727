import WavyBgBottom from "../images/wavy-bg-bottom.png";

const WavyBottom = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${WavyBgBottom})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "159px",
      }}
    ></div>
  );
};

export default WavyBottom;
