const ItemBox = ({ value, text }) => {
  return (
    <div className="item-box">
      <div className="item-box-top">
        <h3 className="t-dark text-center">{value}</h3>
      </div>
      <div className="item-box-bottom">
        <h4 className="t-white text-center">{text}</h4>
      </div>
    </div>
  );
};

export default ItemBox;
